import * as axios from "axios";
import {isWidthDown} from "@material-ui/core/withWidth";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/pl";

//dirty draft
//some prehistory i think..
export const dateParser = v => {
    console.log('------date parser', v);
    // v is a `Date` object
    if (!(v instanceof Date) || isNaN(v)) return;
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};


//fixme support eng locale format
export const formatDate = (val, opts = {}) => {

    if (!(moment(val).isValid())) return val;

    const m = (opts.useUtc)?moment(val).utc():moment(val);

    if (opts.hideDate)
        return m.format("HH:mm:ss");
    if (opts.hideDateSeconds)
        return m.format("HH:mm");
    if (opts.hideTime)
        return m.format("DD-MM-YYYY");
    if (opts.hideTimeShort)
        return m.format("DD MMM");
    if (opts.showWeekDay)
        return m.format("dddd DD-MM-YYYY, HH:mm:ss");
    else {
        return m.format("DD-MM-YYYY, HH:mm:ss");
    }
};

export const dateFormatter = (v, opts = {} ) => {
    return formatDate(v, {...opts, hideTime:true})
};

export const dateFormatterShort = (v, opts = {}) => {
    return formatDate(v, {...opts, hideTimeShort:true})
};

export const timeFormatterShort = (v, opts = {}) => {
    return formatDate(v, {...opts, hideDateSeconds:true})
};

export const timeFormatter = (v, opts = {}) => {
    return formatDate(v, {...opts, hideDate:true})
};

export const dateTimeWeekDayFormatter = (v, opts = {}) => {
    return formatDate(v, {...opts, showWeekDay: true})
};

export const dateTimeFormatter =  (v, opts = {}) => {
    return formatDate(v, opts)
};

//hmm. every call is now?
export const utcOffset = () => {
    return moment().format("Z");
};

export const timeAgo = (v) => {
    return moment(v).fromNow();
};



//rounds to next interval in minutes, like 5, 10, 15, 30
//todo add test for valid values
export const round2NextInterval = (v, minutes) => {

    const validityTest = (60/minutes);
    if (validityTest !== Math.round(validityTest)) {
        console.error('wrong interval value');
        minutes = 1;
    }

    let clone = moment(v).clone();
    const roundedMinutes = Math.ceil(clone.minute() / minutes) * minutes;
    return clone.minute(roundedMinutes).second(0).milliseconds(0);
};


// const testV = '2013-02-08 09:56:26';
// const rounded = round2NextInterval(testV, 15);
// console.warn('interval test', testV, rounded.format());

// for usage without new
export function Holidays(country) {
    return new _Holidays(country)
}

class _Holidays {

    country = '';

    static holidays = {
        pl: {
            2019: [
                '2019-01-01',
                '2019-01-06',
                '2019-04-21',
                '2019-04-22',
                '2019-05-01',
                '2019-05-03',
                '2019-06-20',
                '2019-08-15',
                '2019-11-01',
                '2019-11-11',
                '2019-12-25',
                '2019-12-26',
            ],
            2020: [
                '2020-01-01',
                '2020-01-06',
                '2020-04-12',
                '2020-04-13',
                '2020-05-01',
                '2020-05-03',
                '2020-05-31',
                '2020-06-11',
                '2020-08-15',
                '2020-11-01',
                '2020-11-11',
                '2020-12-25',
                '2020-12-26',
            ],

            2021: [
                '2021-01-01',
                '2021-01-06',
                '2021-04-04',
                '2021-04-05',
                '2021-05-01',
                '2021-05-03',
                '2021-05-23',
                '2021-06-03',
                '2021-08-15',
                '2021-11-01',
                '2021-11-11',
                '2021-12-25',
                '2021-12-26',
            ],

            2022: [
                '2022-01-01',
                '2022-01-06',
                '2022-04-17',
                '2022-04-18',
                '2022-05-01',
                '2022-05-03',
                '2022-06-05',
                '2022-06-16',
                '2022-08-15',
                '2022-11-01',
                '2022-11-11',
                '2022-12-25',
                '2022-12-26',
            ],

            2023: [
                '2023-01-01',
                '2023-01-06',
                '2023-04-09',
                '2023-04-10',
                '2023-05-01',
                '2023-05-03',
                '2023-05-28',
                '2023-06-08',
                '2023-08-15',
                '2023-11-01',
                '2023-11-11',
                '2023-12-25',
                '2023-12-26',
            ],
            2024: [
                '2024-01-01',
                '2024-01-06',
                '2024-03-31',
                '2024-04-01',
                '2024-05-01',
                '2024-05-03',
                '2024-05-19',
                '2024-05-30',
                '2024-08-15',
                '2024-11-01',
                '2024-11-11',
                '2024-12-25',
                '2024-12-26',
            ],
            2025: [
                '2025-01-01',
                '2025-01-06',
                '2025-04-20',
                '2025-04-21',
                '2025-05-01',
                '2025-05-03',
                '2025-06-08',
                '2025-06-19',
                '2025-08-15',
                '2025-11-01',
                '2025-11-11',
                '2025-12-24',
                '2025-12-25',
                '2025-12-26',
            ],
        }
    };

    constructor(country) {
        if (!country) throw new Error('Please select country in constructor of Holidays class');
        this.country = country;
    };

    async fetchAndSupplyHolidays(year) {
        await axios({
            method: "GET",
            url: 'https://holidayapi.pl/v1/holidays',
            params: {country: this.country, year: year}
        }).then(response => {
            _Holidays.holidays[this.country][year] = Object.keys(response.data.holidays);
            console.log("holidays fetch", _Holidays.holidays[this.country][year])
        });
    }

    hasYear(year) {
        return _Holidays.holidays[this.country][year] !== undefined;
    }

    hasYears(from, to) {
        for(let year = from; year <= to; year++) {
            if(_Holidays.holidays[this.country][year] === undefined) return false;
        }
        return true;
    }

    includes(date) {
        const year = moment(date).year();
        if(!this.hasYear(year)) throw new Error(`No holidays data for year ${year}, country '${this.country}'. Please call fetchAndSupplyHolidays() first.`);

        for (const holiday of _Holidays.holidays[this.country][year]) {
            if (moment(holiday).isSame(date)) {
                return true;
            }
        }
        return false;
    };

   calcBusinessDays(startDate, endDate) {
        let day = moment(startDate);
        let businessDays = 0;

         while (day.isSameOrBefore(endDate, 'day')) {
            if (day.day() !== 6 && day.day() !== 0 && !this.includes(day)) businessDays++;
            day.add(1, 'd');
        }
        return businessDays;
    }
}


export const adjustTimeFormatterToScreen = (option = {time: true}, width) => {

    if(option.time && option.date) {
        return dateTimeFormatter;
    }
    else if(option.time) {
        if(isWidthDown('md', width)) {
            return timeFormatterShort;
        }
        else {
            return dateTimeFormatter;
        }
    }
    else if(option.date) {
        if(isWidthDown('md', width))
        {
            return dateFormatterShort;
        }
        else {
            return dateTimeFormatter;
        }
    }
    else throw `Cannot adjust time formatter with this options: ${option.toString()}, width: ${width}`;

};
export const setMomentLocale = (locale) => {

    switch (locale) {
        case 'pl':
            moment.locale('pl');
            break;
        case 'en':
            moment.locale('en-gb');
            break;
        default:
            return {};
    }
};

export const defineCustomMomentObjects = () => {
    moment.defineLocale('en-notam', {
        parentLocale: 'en',
    });
    moment.updateLocale('en-notam', {
        monthsShort: [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ],
        weekdaysShort : ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
    });
};
